<template>
  <!-- <keep-alive include="scheduling-events"> -->
  <router-view />
  <!-- </keep-alive> -->
</template>

<script>
export default {
  name: "scheduling",
};
</script>
